@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@500&display=swap');

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-image: url('./Assets/background.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.content-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.responsive-logo {
  justify-content: center;
  display: flex;
}

.responsive-text-1 {
  text-align: center;
  margin-top: 16px;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 500;
  font-size: 24px;
  color: white;
}

.responsive-text-2 {
  text-align: center;
  margin-top: 32px;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: white;
}

.responsive-text-3 {
  text-align: center;
  margin-top: 256px;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 500;
  font-size: 24px;
  color: white;
}

/* Media query for screens smaller than 500px */
@media (max-width: 809px) {

  .responsive-text-1{
    text-align: center;          /* Makes the image behave as a block element */
    margin-top: 16px;
    font-family: 'Space Grotesk', sans-serif; /* Sets the font to Space Grotesk */
    font-weight: 500;                  /* Medium weight (500) */
    font-size: 18px; 
    color: white;  
  }

  .responsive-text-2{
    text-align: center;              /* Centers the text horizontally */
    margin-top: 16px;               /* Adds 450px of top margin */
    font-family: 'Space Grotesk', sans-serif; /* Sets the font to Space Grotesk */
    font-weight: 400;                  /* Medium weight (500) */
    font-size: 14px; 
    color: white;                   /* Font size for larger screens */
  }

  /* Break the text into three lines on small screens */
  .responsive-text-2 span.break-on-small-screen {
    display: block;               /* Makes this part of the text appear on a new line */
  }

  .responsive-text-3{
    text-align: center;              /* Centers the text horizontally */
    margin-top: 128px;               /* Adds 450px of top margin */
    font-family: 'Space Grotesk', sans-serif; /* Sets the font to Space Grotesk */
    font-weight: 500;                  /* Medium weight (500) */
    font-size: 20px; 
    color: white;                   /* Font size for larger screens */
  }

  .responsive-logo{
    display: block;               /* Makes the image behave as a block element */         /* Applies 247px top margin and centers the logo horizontally */
    width: 174px;                 /* Set the width of the logo */
    height: auto;             /* Makes the image behave as a block element */
    margin: 240px auto 0;
    
  }
  .content-center{
    margin: 16px;
    display: flex;
    height: 240px;
    align-items: center;
  }
  
  
}
