
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
  width: 100%;
  height: 70px;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  padding-right: 70px;
  padding-left: 70px; 
  margin: 0; 
}

/* Logo */
.navbar-logo {
  object-fit: contain;
}

/* Navigation buttons */
.navbar-buttons {
  display: flex;
  gap: 12px;
}

.navbar-button {
  width: 109px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer; 
}

.navbar-button-label {
  font-family: 'Satoshi', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: black;
}

.dropdown {
  position: relative;
}

.dropdown-icon {
  margin-left: 10px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-button.dropdown {
  width: 143px;
}

/* Get Started Button */
.get-started-button {
  width: 177px;
  height: 44px;
  background-color: #6635CC;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: 'Satoshi', sans-serif;
  font-size: 16px;
  font-weight: 500;
  border: none;
  outline: none;
  cursor: pointer;
}

.arrow-icon {
  margin-left: 10px;
}
